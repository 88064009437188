import React, { createContext, useState, useEffect} from 'react'
const AuthContext = createContext();

export const AuthProvider = ({children}) => {

    const [auth, setAuth] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        authUser();
    }, []);

    

    const authUser = async() =>{
        const token = localStorage.getItem("token");
        const userData = localStorage.getItem("userData");
    
        if(!token || !userData){
            setLoading(false);
            return false;
        }
        
        setAuth({token: token, userData: JSON.parse(userData)});
        setLoading(false);
    }
    
    return (<AuthContext.Provider
    value={{
        auth,
        setAuth,
        loading
    }}>
        {children}
    </AuthContext.Provider>)
}

export default AuthContext;
