
import { Suspense, lazy } from "react";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import { AuthProvider } from "./contextos/AuthProvider";
import { SnackbarProvider } from "notistack";
import LoadingSpinner from "./components/LoadingSpinner";

const Login = lazy(()=> import("./pages/Logueo/Login"));
const Dashboard = lazy(()=> import("./pages/Dashboard"));
const Alarms = lazy(()=> import("./pages/Alarms"));
const Layout = lazy(()=> import("./Layout"));
const NoLayout = lazy(()=> import( "./pages/Logueo/NoLayout"));
const Register = lazy(()=> import("./pages/Logueo/Register"));
const Sugerencias = lazy(()=> import("./pages/Sugerencias"));
const Templates = lazy(()=> import("./pages/Templates"));
const Devices = lazy(()=> import("./pages/Devices"));
const ConfirmarEmail = lazy(()=> import("./pages/Logueo/ConfirmarEmail"));
const CambioPass = lazy(()=> import("./pages/Logueo/CambioPass"));
const RecuperacionPass = lazy(()=> import("./pages/Logueo/RecuperacionPass"));
const Serials = lazy(()=> import("./pages/Serials"));


function App() {
  const [theme, colorMode] = useMode();


  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={4} autoHideDuration={3000} anchorOrigin={{ horizontal: "right", vertical: "top" }}>
          <CssBaseline />
          <Suspense fallback={<LoadingSpinner></LoadingSpinner>}>
            <AuthProvider>
              <Router>
                <div className="app" style={({ height: "100vh" }, { display: "flex", flexDirection: "row" })}>
                  <Routes>
                    <Route path="/" element={<NoLayout />}>
                      <Route index element={<Login />} />
                      <Route path="login" element={<Login />} />
                      <Route path="register" element={<Register />} />
                      <Route path="recuperacionpass" element={<RecuperacionPass/>}/>
                      <Route path="cambiopass" element={<CambioPass/>}/>
                      <Route path="confirmaremail" element={<ConfirmarEmail/>}/>
                      
                    </Route>

                    <Route path="/dashboard" element={<Layout />}>
                      <Route path="" element={<Dashboard />} />
                      <Route path="panel" element={<Dashboard />} />
                      <Route path="devices" element={<Devices />} />
                      <Route path="alarms" element={<Alarms />} />
                      <Route path="sugerencias" element={<Sugerencias />} />
                      <Route path="templates" element={<Templates />} />
                      <Route path="seriales" element={<Serials />} />
                    </Route>
                    <Route path="*" element={(<>
            
                      <div style={{display:"flex", alignContent: "center", justifyContent: "center"}}>
                          <h1 style={{ color: "white" }}>Error 404</h1>
                          <h4 style={{ color: "white" }}>Try</h4>
                        <Link to="/">inicio</Link>
                      </div>
                      
                    </>)} />
                  </Routes>
                </div>
              </Router>
            </AuthProvider>
          </Suspense>
        </SnackbarProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
