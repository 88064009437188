import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Buffer } from 'buffer';

if (typeof window !== 'undefined') {
    window.Buffer = Buffer;
  }
  

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<App />)