import React from "react";

export default function LoadingSpinner({small=false}) {

  if(small == true){
    return (
        <div className="loading-spinner max-h-7 max-w-[55%] md:max-h-8 md:max-w-[64%] lg:max-h-9 lg:max-w-[72%]"></div>
    );
  }
  return (
    <div className="spinner-container">
      <div className="loading-spinner"></div>
    </div>
  );
}